import React, { } from 'react';
import { Container, Card, Carousel } from 'react-bootstrap';

export default function Flotantes2() {


  return (
    <div className="conn vw-100 d-flex animawap bg-transparent">
  <div className="sep w-50 d-flex align-items-center justify-content-center">
  <div className="centrar">
  <Container className='centracol'>
  <div className="rombo esca bg-dark white mb-4 lool2">  <h4 className='text-center'>Roberto | Solidaridad | Fútbol</h4> </div>
      <Carousel>
        <Carousel.Item>
          <Card className='flotante3 romba somb bg-dark white  bor text-center mt-3 lool2'>
            <Card.Body>
              <p>Roberto estaba entrenando para el equipo de fútbol en su escuela. Todos estaban divirtiéndose mucho, pero Roberto notó que uno de sus compañeros de equipo estaba tropezando y tropezando sin parar. A Roberto le dio pena por su compañero y decidió ayudarlo.</p>
            </Card.Body>
          </Card>
        </Carousel.Item>
        <Carousel.Item>
          <Card className='flotante3 romba somb bg-dark white  bor text-center mt-3 lool2'>
            <Card.Body>
              <p>Roberto le habló al entrenador para que le cambiara el calzado a su compañero. Y en un abrir y cerrar de ojos, el compañero de Roberto tenía un par de zapatos nuevos para jugar. Roberto entendió que al ayudar a su compañero, el equipo podría ser más fuerte.</p>
            </Card.Body>
          </Card>
        </Carousel.Item>
        <Carousel.Item>
          <Card className='flotante3 romba somb bg-dark white  bor text-center mt-3 lool2'>
            <Card.Body>
              <p>Al final del partido, el equipo de Roberto ganó por un margen estrecho. Todos se abrazaron y celebraron en la cancha. El compañero de Roberto le agradeció por la ayuda. Roberto sonrió y se dio cuenta que la solidaridad es uno de los mejores valores que uno puede tener.</p>
            </Card.Body>
          </Card>
        </Carousel.Item>
      </Carousel>
    </Container>
    </div>
  </div>
  
  <div className="sep w-50 centrar">
      <div className="centrar">
  <Container className='centracol'>
  <div className="rombo esca bg-dark white mb-4">  <h4 className='text-center'>Sofía | Responsabilidad | TikTok</h4> </div>
      <Carousel>
        <Carousel.Item>
          <Card className='flotante2 romba somb bg-dark white  bor text-center mt-3'>
            <Card.Body>
              <p>Una vez había una niña llamado  Sofía que le encantaba TikTok. Siempre pasaba horas al día grabando divertidos vídeos. Aunque a veces le preocupaba lo que pensarían sus amigos, Sofía seguía publicando sus vídeos.</p>
            </Card.Body>
          </Card>
        </Carousel.Item>
        <Carousel.Item>
          <Card className='flotante2 romba somb bg-dark white  bor text-center mt-3'>
            <Card.Body>
              <p>Un día, Sofía decidió tomarse un descanso de TikTok para realizar otras tareas, como hacer los deberes o ayudar a sus padres con las tareas del hogar. A pesar de que a veces se sentía tentada a volver a grabar vídeos, Sofía recordaba que responsabilidad significaba hacer las cosas correctas, incluso si no eran divertidas.</p>
            </Card.Body>
          </Card>
        </Carousel.Item>
        <Carousel.Item>
          <Card className='flotante2 romba somb bg-dark white  bor text-center mt-3'>
            <Card.Body>
              <p>Al final, Sofía descubrió que el orgullo que sentía al haber sido responsable era mucho mayor que el placer de grabar TikTok. Y aunque todavía disfrutaba de grabar vídeos, ahora lo hacía con responsabilidad.</p>
            </Card.Body>
          </Card>
        </Carousel.Item>
      </Carousel>
    </Container>
    </div>
    </div>
</div>
  );
}
