// export const signUpEvent = () => {
//     window.fbq('track', 'SignUpp');
// }

export const homePageEvent = () => {
    window.fbq('trackCustom', 'ViewHomePage-Web');
}

export const pricingPageEvent = () => {
    window.fbq('trackCustom', 'ViewPricingPage-Web');
}

export const quiereComprarEvent = () => {
    window.fbq('trackCustom', 'ClickVamosAlla-Web');
}

//MODIFICO EL DE ARRIBA Y TODOS LOS VAMOS ALLA HAGAN AXCTIVO ESE EVENTO UNO SOL OPARA PIXEL AYUDA

// export const quiereComprar2Event = () => {
//     window.fbq('trackCustom', 'ClickLegoPlan2');
// }

// export const quiereComprar3Event = () => {
//     window.fbq('trackCustom', 'ClickLegoPlan3');
// }

// probando trackcustom

// export const pricingPageEvent = () => {
//     window.fbq('track', 'ViewPricingPage');
// }

// añadido5nov