import React, { useEffect} from 'react';
import Nave2 from './Nave2';
import Hero from './Hero';
import Faq from './Faq';
import Footer from './Footer';
import Carditas from './Carditas';
import Reviews from './Reviews';
import CookiePopup from './CookiePopup';

import { homePageEvent } from './facebookPixelEvent';

export default function Home() {

  useEffect(() => {
    homePageEvent();
  }, []);

  return (
    <>
    <Nave2></Nave2>
<Hero></Hero>
<Carditas></Carditas>
<Reviews></Reviews>
<Faq></Faq>
<Footer></Footer> 
<CookiePopup></CookiePopup>
</>

  )
}
