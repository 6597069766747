import React, { useEffect} from 'react';
import Footer from './Footer';
import Nave2 from './Nave2';
import { Container } from 'react-bootstrap'

import { pricingPageEvent, quiereComprarEvent } from './facebookPixelEvent';

export default function Precios() {

    useEffect(() => {
       pricingPageEvent();
      }, []);

      const onCliquete = () => {
        quiereComprarEvent();
        console.log('clic Vamos Alla')
      };
      // const onCliquete2 = () => {
      //   quiereComprar2Event();
      //   console.log('clic2')
      // };
      // const onCliquete3 = () => {
      //   quiereComprar3Event();
      //   console.log('clic3')
      // };

  return (
<>
    <Nave2></Nave2>
    <Container className='anteHero'></Container>
    <div className=' container  dd mb-2'>
        <div className="row">
          <h1 className='text-center mt-4 mb-4'>PAQUETES</h1>
          {/* SPECIF.AMITADPRECIOHASTENEROPORINAUG 50%  //<<<-OFFER + COPY-OF-PRICES*/}
            <div className="col-lg-4">
                <div className="card mb-5 mb-lg-0 lool hong">
                    <div className="card-body">
                        <h5 className="card-title text-muted text-uppercase text-center">Padres Tradicionales®</h5>
                        <h6 className="card-price text-center">2,95 €</h6>
                        <hr/>
                        <ul className="fa-ul">
                            <li className='mb-2'><span className="fa-li"><i className="fas fa-check"></i></span>Fomenta la imaginación de tu hijo con 3 cuentos únicos</li>
                            <li className='mb-2'><span className="fa-li"><i className="fas fa-check"></i></span>Enseña valores esenciales a través de narrativas cautivadoras</li>
                            <li className='mb-2'><span className="fa-li"><i className="fas fa-check"></i></span>Personaliza cada historia con el nombre de tu hijo y sus intereses</li>
                            <li className='mb-2'><span className="fa-li"><i className="fas fa-check"></i></span><strong>Ampliar 1 cuento</strong></li>
                            <li className='mb-2'><span className="fa-li"><i className="fas fa-check"></i></span><strong>Escuchar 1 cuento (Hasta Enero)</strong></li>

                            {/* <li className="text-muted"><span className="fa-li"><i className="fas fa-times"></i></span>Ampliar cuentos</li>  */}
                            {/* <li className="text-muted"><span className="fa-li"><i className="fas fa-times"></i></span>Escuchar cuentos</li>  */}
                        </ul>
                        <div className="d-flex justify-content-center">
                        {/* <a href="https://app.cuentameotro.com/signBuy" className="btn btn-block btn-success text-uppercase">¡Vamos allá!</a> */}
                        <a
                    href="https://app.cuentameotro.com/signBuy"
                    className="btn btn-block btn-success text-uppercase"
                    onClick={onCliquete}
                  >
                    ¡Vamos allá!
                  </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-lg-4">
                <div className="card mb-5 mb-lg-0 tante hong">
                    <div className="card-body">
                    <h5 className="card-title text-muted text-uppercase text-center">Padres Destacados®</h5>
                    <h6 className="card-price text-center">4,95 €</h6>
                        <hr/>
                        <ul className="fa-ul">
                            <li className='mb-2'><span className="fa-li"><i className="fas fa-check"></i></span><strong>Duplica la diversión y el aprendizaje con 6 relatos personalizados</strong></li>
                            <li className='mb-2'><span className="fa-li"><i className="fas fa-check"></i></span>Inspira a tu hijo a través de una variedad de historias emocionantes</li>
                            <li className='mb-2'><span className="fa-li"><i className="fas fa-check"></i></span>Crea recuerdos especiales con narrativas adaptadas a sus gustos</li>
                            <li className='mb-2'><span className="fa-li"><i className="fas fa-check"></i></span><strong>Ampliar 3 cuentos</strong></li>
                            <li className='mb-2'><span className="fa-li"><i className="fas fa-check"></i></span><strong>Escuchar 2 cuentos</strong></li>
                        </ul>
                        <div className="d-flex justify-content-center">
                        {/* <a href="https://app.cuentameotro.com/signBuy2" className="btn btn-block btn-success text-uppercase tante">¡Vamos allá!</a> */}
                        <a
                    href="https://app.cuentameotro.com/signBuy2"
                    className="btn btn-block btn-success text-uppercase"
                    onClick={onCliquete}
                  >
                    ¡Vamos allá!
                  </a>
                        </div>
                    </div>
                </div>
            </div>
           
            <div className="col-lg-4 mb-5">
                <div className="card lool hong">
                    <div className="card-body">
                    <h5 className="card-title text-muted text-uppercase text-center">Super-Papás®</h5>
                    <h6 className="card-price text-center">7,95 €</h6>
                        <hr/>
                        <ul className="fa-ul">
                            <li className='mb-2'><span className="fa-li"><i className="fas fa-check"></i></span>Una colección de 10 fábulas para un viaje inagotable de aprendizaje</li>
                            <li className='mb-2'><span className="fa-li"><i className="fas fa-check"></i></span><strong>Desarrolla una biblioteca de valores y aventuras para tu hijo</strong></li>
                            <li className='mb-2'><span className="fa-li"><i className="fas fa-check"></i></span>¡Ahorra más y disfruta de un conjunto de cuentos exclusivos!</li>
                            <li className='mb-2'><span className="fa-li"><i className="fas fa-check"></i></span><strong>Ampliar 7 cuentos</strong></li>
                            <li className='mb-2'><span className="fa-li"><i className="fas fa-check"></i></span><strong>Escuchar 4 cuentos</strong></li>
                        </ul>
                        <div className="d-flex justify-content-center">
                        {/* <a href="https://app.cuentameotro.com/signBuy3" className="btn btn-block btn-success text-uppercase">¡Vamos allá!</a> */}
                        <a
                    href="https://app.cuentameotro.com/signBuy3"
                    className="btn btn-block btn-success text-uppercase"
                    onClick={onCliquete}
                  >
                    ¡Vamos allá!
                  </a>
                        </div>
                        </div>
                </div>
            </div>
        </div>
{/* <div className="d-flex gap-4">
        <div className="cajera esca mb-5"></div>
        <div className="cajera esca mb-5"></div>
        <div className="cajera esca mb-5"></div>
    </div> */}
    </div>
    <Footer></Footer>

    
    </>
  )
}
