import React from 'react'
import { Container, Card } from "react-bootstrap";
import Nave2 from './Nave2';
import Footer from './Footer';

export default function Terms

() {
  return (
   <>
    <Nave2></Nave2>
    <Container className='anteHero'></Container>
    <Container className='p-5'>
        <h1 className='text-center boldd mb-4'>Términos & Condiciones de Uso</h1>
        <Card className='lool'><Card.Body className='p-5'>
        <h2>1. Información previa a la contratación</h2>
        {/* meterMarginenP SERVPRODDDIGITALINFOPRODENTREMED*/}
<p>
        Estos Términos y Condiciones regularán las relaciones surgidas entre CUENTAMEOTRO.COM y el Usuario que contrate los servicios ofrecidos por esta web.
        </p><p>
Estos Términos y Condiciones han sido elaboradas conforme a lo establecido en la normativa vigente en España reguladora de los Servicios de la Sociedad de la Información y de comercio electrónico, de acuerdo a la Ley General para la Defensa de los Consumidores y Usuarios.
</p><p>
De acuerdo al artículo 10 de la Ley 34/2002, del 11 de julio, de servicios de la Sociedad de la Información y Comercio Electrónico (LSSICE), se hacen constar los siguientes datos:
</p><p>
• Denominación comercial: CUENTAMEOTRO.COM
</p><p>
• Nombre: CUÉNTAME OTRO, SOCIEDAD LIMITADA
</p><p>
• CIF: ''
</p><p>
• Dirección: ''
</p><p>
• Nombre del dominio: https://cuentameotro.com
</p><p>
• Correo electrónico: hola@cuentameotro.com
</p><p>
• Actividad: Software as a Service (SaaS). Servicios digitales con Inteligencia Artificial. Servicio de compra única/suscripción de generación de cuentos, fábulas o textos con inteligencia artificial.
</p><p>
La utilización y/o la contratación de cualquiera de los servicios de esta Web suponen la aceptación expresa, como Usuario, sin reservas de ninguna clase, de todas y cada una de los Términos y Condiciones aquí descritos.
</p><p>
CUENTAMEOTRO.COM informa que los trámites para efectuar la contratación de los servicios prestados son los que se describen en estos Términos y Condiciones de Contratación, así como aquellos otros trámites que se indiquen en pantalla durante la navegación, de manera que el Usuario declara conocer y aceptar dichos trámites como necesarios para acceder a los servicios ofrecidos por CUENTAMEOTRO.COM.
</p><p>
Cualquier modificación y/o corrección de los datos proporcionados por los Usuarios durante la navegación deberá efectuarse según las indicaciones incluidas en esta web.
</p>

<br></br>
<h2>2. Proceso de Contratación</h2>
<p><strong>
2.1 Servicio prestado por CUENTAMEOTRO.COM
</strong></p>
<p>
CUENTAMEOTRO.COM es un página de generación de cuentos, textos y audios con la ayuda de inteligencia artificial.
CUENTAMEOTRO.COM también puede ofrecer la venta del software mediante paquetes o productos únicos y la suscripción a cursos o servicios.
</p>
<p><strong>
2.2 FREE TRIAL
</strong></p>
<p>
CUENTAMEOTRO.COM puede ofrecer Free Trial, de duración diversa, para dar a conocer la herramienta y sus funcionalidades.
</p><p>
Los FREE TRIAL no generan en el Usuario ningún derecho de devolución o compensación económica puesto que es un entrenamiento gratuito. No obstante, los participantes tienen pleno derecho en cuanto a sus datos, pudiendo ejercer en todo momento el derecho de acceso, rectificación o supresión de datos personales. Estos derechos se desarrollan en el apartado de Política de Privacidad.
</p><p>
Este servicio recomendados, actuando como intermediario entre los Usuarios y los organizadores. A través de la web los Usuarios podrán consultar los precios de distintos eventos y contratar online el servicio que mejor se adapte a sus necesidades.
</p>
<p><strong>
2.3 Compra del servicio
</strong></p>
<p>
Para realizar una compra el Usuario deberá acceder a la web e introducir los datos personales y de pago que se le pidan para tramitar la compra.
</p><p>
Los precios establecidos en la web incluyen los diferentes impuestos al consumo tales como IVA, teniendo en cuenta las peculiaridades fiscales de (Portugal, Europa). El sistema automático de solicitudes recoge los datos que el Usuario introduce.
</p><p>
El Usuario deberá incluir los datos del pago de los servicios contratados. Una vez se complete el proceso, el Usuario recibirá un email confirmando el pedido y con el número de identificación.
</p><p>
Con la realización del pago se entienden aceptados los Términos y Condiciones expuestos que el usuario ha podido consultar con anterioridad a la compra o en cualquier momento.
</p>


<br></br>
<h2>3. Formas de Pago</h2>
<p>Las formas de pago aceptadas en la web de CUENTAMEOTRO.COM son principalmente Stripe (Incluye: Tarjeta de crédito, Apple Pay entre otros.) y Paypal, sin excluir la posibilidad de poder usar otros medios de pago legalmente aceptados.</p>
{/* WEB3 CRPT lgnggleaplpay */}
<br></br>
{/* CUENTSVRIOSIDIOMSANDCLIENTUILANGU */}
<h2>4. Idiomas</h2>
<p>El servicio se presta en los siguientes idiomas: Español, Inglés, Eslovaco, Alemán, Búlgaro, Checo, Danés, Griego, Estoniano, Finlandés, Húngaro, Italiano, Indonesio, Japonés, Lituano, Letón, Holades, Polaco, Rumano, Ruso, Esloveno, Sueco, Turco y Chino.</p>
<br></br>
<h2>5. Funneles de Venta y publicidad</h2>
<p>CUENTAMEOTRO.COM puede utilizar campañas de publicidad y técnicas de venta a través de las que pueda pedir ciertos datos personales a fin de permitir ofrecer el servicio solicitado, como por ejemplo entregar un presupuesto. En todo caso, las personas interesadas pueden ejercer sus derechos en cualquier momento sobre los datos personales.</p>
<br></br>
<h2>6. Disclaimer</h2>
<p>CUENTAMEOTRO.COM no se hacen responsables de los posibles fallos o errores del servicio puesto que está en desarrollo y los usuarios conocen esta circunstancia con este Disclaimer. Así mismo, CUENTAMEOTRO.COM no se hace responsable de los cuentos/textos/audios que generen los usuarios por su cuenta y riesgo, siendo ellos sus únicos responsables.

</p>
<br></br>
<h2>7. Derecho de desistimiento</h2>
<p>
El derecho legal de desistimiento es de 14 días. Ahora bien, la norma prevé diferentes excepciones por su naturaleza. Una de ellas es cuando se trate de contenidos digitales sin soporte material.
</p><p>
No obstante, desde CUENTAMEOTRO.COM queremos ofrecerte un periodo de desistimiento comercial.
</p><p>
Así, se ofrece un derecho de desistimiento en el que, si durante los próximos 5 días naturales posteriores a la compra, el usuario no ha generado al menos 2 cuentos, 2 ampliaciones de cuentos o 2 audio-cuentos de su plan, entonces, podrá optar a un reembolso íntegro del precio de compra.
</p>
<br></br>
<h2>8. Gestión de reclamaciones</h2>

<p><strong>
8.1 Responsabilidad máxima de CUENTAMEOTRO.COM
</strong></p>
<p>
La responsabilidad máxima de CUENTAMEOTRO.COM se regirá por lo dispuesto en la legislación nacional e internacional que resulte de aplicación y, en concreto, de la Ley General de defensa de consumidores y usuarios.
</p>
<p><strong>
8.2 Modalidad y plazos de reclamación
</strong></p>
<p>
En el caso de tener que realizar una reclamación, el Usuario debe realizarla a través de la web de CUENTAMEOTRO.COM o enviarla por correo electrónico. Además, deberá aportar el justificante de compra.
</p><p>
Dentro del plazo de un mes, CUENTAMEOTRO.COM le podrá ayudar/aconsejar para realizar todos los trámites necesarios que lleven a resolver la incidencia. El Usuario puede otorgar el acceso a la reclamación a terceros siempre que se aporte autorización.
</p><p>
Los hechos constitutivos de la reclamación deben de estar disponibles para su comprobación, sin alteraciones. Si se refiere a algún hecho sucedido en un evento offline o online se podrán aportar fotos, vídeos, audios u otros elementos de prueba.
</p><p>
De no llegarse a un acuerdo, el Usuario podrá buscar otras vías, como las Oficinas de Información a los Consumidores.
</p>

<br></br>
<h2>9. Cancelación de Pedidos</h2>
<p>CUENTAMEOTRO.COM no realizará ningún reembolso por cancelación de servicios, salvo que se ejercite el derecho de desistimiento dentro de los 5 días naturales siguientes a la compra.</p>
<br></br>
{/* <h2>10. Baja en el servicio</h2>
<p>Identifican la ubicación del usuario.</p>
<br></br> */}
{/* <h2>10. Grupo de Telegram de Cuéntame Otro</h2>
{/* registered patented and trademark smbl   Y PARA NOVEDADES DEL SERVICIO Y QUEJS
<p>Las cookies propias son generadas por Cuéntalo Tu Mism@, mientras que las de terceros provienen de servicios externos como Facebook, Twitter o Google.</p>
<br></br> */}
<h2>10. Comunidad, Foro & Newsletter</h2>
<p>Comparte tu pasión. [Ponto más Info.]
</p>
<br></br>
<h2>11. Régimen jurídico supletorio</h2>
<p>En todo lo no previsto en las presentes Condiciones Generales, se estará a lo previsto en el Texto Refundido de la Ley General para la Defensa de los Consumidores y Usuarios y en el resto de legislación nacional e internacional de aplicación.</p>
<br />
<h2>12. Política de uso razonable</h2>
<p>
Todos los usuarios de CUÉNTAME OTRO están sujetos a una política de uso justo en la que:
</p><p>
- Los usuarios no pueden generar contenido con la ayuda de scripts o herramientas de automatización
- Los usuarios no pueden tener varias personas iniciando sesión en su cuenta
 {/* (existe un apartado de perfil donde puedes agregar miembros a tu espacio de trabajo) */}
</p><p>
Advertencias:
</p><p>
-Si nuestro equipo o sistema le identifica por cualquier incumplimiento de la política de uso justo en el plan seleccionado, primero se le bloqueará el uso de Cuéntame Otro y recibirá la primera advertencia.
</p>
{/* - Si un miembro del equipo abusa de la política de uso justo, se le eliminará de la cuenta y se le pedirá que cree su propio plan basado en el crédito. */}
<p>
- Además, si continúa abusando de nuestras políticas después de dos advertencias, se te prohibirá el uso de Cuéntame Otro por completo. No recibirá un reembolso de ningún cargo.
{/* registered */}
</p>
{/* -Si el miembro de la plataforma sigue incumpliendo después de ser notificado, se le dará un segundo aviso y se le dará acceso a un plan basado en créditos. */}
{/* <p>
Además, si continúa abusando de nuestras políticas después de dos advertencias, se le prohibirá el uso de Escríbelo por completo. No recibirá un reembolso de ningún cargo si está prohibido.
</p> */}
<br />
<p><strong>Estos Términos & Condiciones de Uso están actualizados a 31 de Octubre de 2023.</strong></p>

</Card.Body></Card>
        
    </Container>
    <Footer></Footer>
    </>
  )
}
