import React from 'react'
import { Container, Card } from "react-bootstrap";
import Nave2 from './Nave2';
import Footer from './Footer';

export default function Privacy

() {
  return (
   <>
    <Nave2></Nave2>
    <Container className='anteHero'></Container>
    <Container className='p-5'>
        <h1 className='text-center boldd mb-4'>Política de Privacidad</h1>
        <Card className='lool'><Card.Body className='p-5'>
        <h2>Ámbito de Aplicación</h2>
<p>
        CUENTAMEOTRO.COM se compromete a respetar la privacidad de quienes visitan y utilizan su web, alojada bajo la URL https://cuentameotro.com (el “Sitio Web”).
        </p><p>
Esta Política de Privacidad tiene como finalidad informar a las personas usuarias acerca del tratamiento de los datos personales que se recogen a través del Sitio Web y de los servicios asociados a éste.
</p><p>
La utilización de https://cuentameotro.com o de cualquiera de sus servicios asociados (como los funneles de venta) implica la aceptación por la persona usuaria de las disposiciones contenidas en esta Política de Privacidad y que sus datos personales sean tratados según se establece ene ella y de acuerdo a los usos y facultades que prevé la normativa.
</p>


<br></br>
<h2>Responsable – ¿Quién es el responsable del tratamiento de los datos?</h2>
<p>
Le informamos que los datos personales facilitados a través de los formularios u otro 
uso derivado de la navegación en CUENTAMEOTRO.COM, así como su dirección de correo electrónico, 
han sido incluidas en un tratamiento de datos titularidad de CUENTAME OTRO, SOCIEDAD LIMITADA, 
con CIF '', correo electrónico hola@cuentameotro.com y domicilio social en ''.
</p><p>
La persona encargada de la gestión y funcionamiento de la web es CUENTAME OTRO, SOCIEDAD LIMITADA, con CIF núm. '' y correo de contacto hola@cuentameotro.com.
</p>
<br></br>
<h2>Finalidades</h2>

<p>
Se informa que los datos pueden ser tratados con la finalidad de facilitarle, por medios electrónicos, información comercial y novedades de la web y de nuestros productos o servicios, siempre que nos autorice su tratamiento.
</p><p>
Sus datos podrán ser objeto de la elaboración de perfiles, con el fin de aplicar un plan de fidelización de acuerdo a la vinculación comercial de las personas usuarias y clientes y ofrecer ofertas que sean más adecuadas a sus preferencias.
</p><p>
Si el usuario contacta con https://cuentameotro.com a través de la web, sus datos personales se utilizarán para darle respuesta a las peticiones de información y otras consultas que hubiera formulado.
</p><p>
En cualquier momento puede oponerse a este tipo de envíos, enviando un correo electrónico a la dirección hola@cuentameotro.com indicando en el asunto «Baja de la lista de distribución» o pinchando en el enlace de baja de suscripción que encontrará en los envíos.
</p>

<br />
<h2>Sobre la política de privacidad</h2>
<p>
CUENTAMEOTRO.COM está especialmente sensibilizada en la protección de los datos de las personas usuarias y clientas a los que se accede a través de https://cuentameotro.com.
</p><p>
Todo ello con el fin de resultar una web y un servicio transparente con su comunidad y facilitar que las personas que accedan a la web decidan, libre y voluntariamente, qué información desean facilitar en cada momento.
</p>
<br></br>
<h2>Confidencialidad y seguridad</h2>
<p>
{/* | APP.CUENTAMEOTRO.COM [STRIPE] */}
Las personas usuarias dejarán los datos y realizarán el pago a través de CUENTAMEOTRO.COM. Estos datos personales serán tratados con total confidencialidad. Además, se informa que https://cuentameotro.com cederá los datos a la persona responsable del evento organizado, además de aquellos otros que pudieran estar vinculados con el evento, pero no a terceros sin relación alguna ni motivo justificado.
</p><p>
https://cuentameotro.com informa que ha adoptado las medidas de carácter técnico y organizativas necesarias a fin de garantizar la seguridad y confidencialidad y para evitar la alteración, pérdida, tratamiento o acceso no autorizado de los datos.
</p><p>
Todo ello teniendo en cuenta el estado de la evolución tecnológica, la naturaleza de los datos almacenados y los riesgos a que están expuestos, ya provengan de la acción humana, acción automatizada o robot o del medio físico o natural.
</p><p>
Sin embargo, no existe ningún sistema completamente seguro, por lo que https://cuentameotro.com no puede garantizar que los datos puedan estar totalmente protegidos en todo momento y bajo cualquier circunstancia ajena a CUENTAMEOTRO.COM.
</p>
<br></br>
<h2>Derechos de acceso, rectificación, cancelación y oposición</h2>
<p>En cualquier momento el usuario podrá acceder, rectificar, cancelar u oponerse al tratamiento de sus datos personales dirigiéndose a la pestaña “Contacto” del Sitio Web, o bien seleccionando la opción “cancelar la suscripción” de cualquier correo electrónico enviado por el Sitio Web.</p>
<br></br>
<h2>Derechos de las personas usuarias</h2>
<p>
La persona interesada de los datos personales en todo caso podrá ejercitar los derechos que le asisten, de acuerdo con el Reglamento General de Protección de Datos y del Ley Orgánica 3/2018 de Protección de datos personales y garantía de los derechos digitales. Estos derechos son:
</p><p>
A. Derecho de acceso: a acceder y conocer los datos que se almacenan sobre ti.
</p><p>
B. Los derechos de rectificación y supresión podrán ser ejercitados siempre que el interesado considere que los datos recogidos en nuestros tratamientos son inexactos, incompletos, inadecuados o excesivos.
</p><p>
C. El derecho de oposición podrá ser ejercitado siempre que la persona interesada quiera oponerse al tratamiento de sus datos personales, tanto por si los datos se tratan lícitamente como por interés personal legítimo o por consentimiento relativo para fines publicitarios.
</p><p>
D. El derecho de portabilidad podrá ser ejercitado siempre que la persona interesada considere que los datos recogidos en nuestros tratamientos deben ser devueltos a la persona titular de los datos, o bien a otro tercero (Encargado de tratamiento).
</p>
<br></br>
<h2>Spam</h2>
{/* oCuentameOtroOnly andother //from local */}
<p>CUENTAMEOTRO.COM no aprueba la práctica del “spamming”. Esta palabra significa el envío masivo y repetido de mensajes de correo electrónico no solicitados, normalmente de carácter comercial, a personas con las cuales el remitente no ha mantenido ningún contacto anterior, o personas que hayan expresado su deseo de no recibir tales mensajes.
En el caso de que https://cuentameotro.com considere que determinada información puede ser de interés para las personas usuarias, se reserva el derecho a facilitarles dicha información por correo electrónico, previo su consentimiento y ofreciendo siempre la posibilidad de excluirse de ese servicio de información.</p>
<br></br>
<h2>Funneles de Venta y publicidad</h2>
<p>CUENTAMEOTRO.COM puede utilizar campañas de publicidad y técnicas de venta a través de las que pueda pedir ciertos datos personales a fin de permitir ofrecer el servicio solicitado, como por ejemplo entregar un presupuesto. En todo caso, las personas interesadas pueden ejercer sus derechos en cualquier momento sobre los datos personales.</p>
<br></br>
<h2>Disclaimer</h2>
<p>El establecimiento no se hace responsable de las opiniones y comentarios que personas ajenas a la organización publiquen en redes sociales, webs, foros, reseñas o apps sobre CUENTAMEOTRO.COM o cualquiera de sus servicios, personal o instalaciones, siendo el autor o autora el único responsable de su veracidad.</p>
<br></br>
<h2>Modificación de la política de privacidad</h2>
<p>CUENTAMEOTRO.COM se reserva el derecho de modificar en cualquier momento la presente política con el objeto de adaptarla a las novedades legislativas, siendo publicadas las sucesivas versiones en el sitio web. Los cambios serán comunicados en nuestra página Web con la antelación que sea necesaria, sin perjuicio de pedir el consentimiento necesario de las personas afectadas por el cambio normativo, cuando éste no se considerase otorgado con arreglo a los términos de la presente política.</p><p> Cualquier duda, pregunta o comentarios que pudiera albergar con referencia a la presente normativa, no dude en consultarlas dirigiendo su comunicación al respecto a: hola@cuentameotro.com.</p>
<br></br>
<br></br>
<p><strong>Estas políticas de privadidad están actualizadas a 31 de Octubre de 2023.</strong></p>
</Card.Body></Card>
        
    </Container>
    <Footer></Footer>
    </>
  )
}
