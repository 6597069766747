import React from 'react'
import { Container, Button } from 'react-bootstrap'
import Flotantes2 from './Flotantes2'

export default function Hero() {
  return (
    <>
    <Container className='anteHero'></Container>
    <Container className='text-center w-75 mt-5 mb-3'>
        <h1>¿Imaginas poder crear cuentos personalizados instantáneamente para tu hijo?</h1>
    </Container>

 <Flotantes2></Flotantes2>

 <Container className='text-center widero mt-2'>
        <Button href='/precios' className='rounded gradient ms-4 me-4 mt-2 ps-5 pe-5 fsfs mb-5 esca'>¡Comenzar ahora!</Button>
    </Container>
 </>








    

  )
}
