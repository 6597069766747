import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap'

function CookiePopup() {
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    const userAcceptedCookies = localStorage.getItem('acceptedCookies');
    if (!userAcceptedCookies) {
      setShowPopup(true);
    }
  }, []);

  const acceptCookies = () => {
    localStorage.setItem('acceptedCookies', 'true');
    setShowPopup(false);
  };

  return (
    showPopup && (
      <div className="cookie-popup">
        <div className="cookie-content">
          <div className="elpe">
          <p>🍪 Este sitio web utiliza cookies para mejorar tu experiencia. Al continuar navegando, aceptas nuestro uso de cookies.</p>
          </div>
          <div className="cookie-buttons">
          <a href="/cookies">Más información</a>
            <Button className='btn btn-light' onClick={acceptCookies}>Aceptar</Button>
           
          </div>
        </div>
      </div>
    )
  );
}

export default CookiePopup;
