import React from 'react';
import { Container, Accordion } from "react-bootstrap";

export default function Faq() {
  return (
    <Container className="mt-5 p-5 animawap">
      <h1 className="text-center">Preguntas Frecuentes</h1>
       <Accordion  className=''> 
     
      <Accordion.Item eventKey="0" className='mb-3 mt-4 esca'>
        <Accordion.Header>¿Cómo funciona el proceso de creación de fábulas personalizadas?</Accordion.Header>
        <Accordion.Body>
        Nuestro proceso es simple. Primero, nos proporcionas el nombre de tu hijo, el valor que deseas enseñar y un tema que le guste, como un objeto, un animal o un concepto. Luego, nuestra plataforma utiliza estos detalles para crear una fábula única y personalizada.
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="1" className='mb-3 esca'>
        <Accordion.Header>¿Puedo ver ejemplos de fábulas personalizadas antes de comprometerme?</Accordion.Header>
        <Accordion.Body>
        Sí, antes de crear tu primera fábula, te mostraremos ejemplos de fábulas personalizadas para que tengas una idea de cómo será el resultado final. Puedes estar seguro de que la historia será especial y adaptada a tu hijo.
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="2" className='mb-3 esca'>
        <Accordion.Header>¿Cómo garantizan que las fábulas sean educativas y apropiadas?</Accordion.Header>
        <Accordion.Body>
        Nuestro servicio se basa en los valores que deseas enseñar a tu hijo. Nos aseguramos de que cada historia sea educativa y apropiada para la edad, ya que tú defines los valores y temas que deseas incorporar.
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="3" className='esca'>
        <Accordion.Header>¿Cuánto tiempo lleva recibir una fábula personalizada?</Accordion.Header>
        <Accordion.Body>
        ¡Recibirás tu fábula personalizada prácticamente al instante! Una vez que proporcionas la información necesaria, nuestro sistema crea la historia de inmediato y te la enviamos en cuestión de segundos para que puedas disfrutarla con tu hijo sin demora.
        </Accordion.Body>
      </Accordion.Item>

    </Accordion>
    </Container>
  );
};