
import React from 'react';
import '../App.css';

import logoImg from '../assets/logo1.png';
import { Container, Nav, Navbar, Button } from "react-bootstrap";

function Nave2() {
  return (
    <Navbar collapseOnSelect expand="lg" className="bg-body-transparent fijoo woww fixed-top">
      <Container className=' w-100'>
        <Navbar.Brand href="/"> <img className='ms-4' src={logoImg} alt="Cuéntame Otro" width="95" /></Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav" className='text-center fsfs'>
          <Nav className="me-auto">
            <Nav.Link className='ms-3' href="/precios">Precios</Nav.Link>
          </Nav>
          <Nav className='boldd'>
            <Nav.Link href="https://app.cuentameotro.com/login">Acceder</Nav.Link>
            <Button href='/precios' className='rounded-pill ms-4 me-4 ps-5 pe-5 fsfs gradient'>¡Comenzar ahora!</Button>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

export default Nave2;