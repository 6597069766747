import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Home from './components/Home';
import Precios from './components/Precios';
import Cookies from './components/Cookies';
import Privacy from './components/Privacy';
import Terms from './components/Terms';
import Legal from './components/Legal';

function App() {
  return (
    <>
<Router>
<Routes>
<Route path='/precios' Component={Precios} />
<Route path='/cookies' Component={Cookies} />
<Route path='/privacy' Component={Privacy} />
<Route path='/terms' Component={Terms} />
<Route path='/legal' Component={Legal} />
<Route exact path='/' Component={Home}/>
</Routes>
</Router>
    </>
  );
}

export default App;
