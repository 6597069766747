import React from 'react'
import { Container, Row, Col, Card } from "react-bootstrap";

import book3 from '../assets/book3.png'
import book2 from '../assets/book2.png';
import book1 from '../assets/book1.png';
import shelf from '../assets/shelf.png';
import wand from '../assets/wand.png';
// import conv from '../assets/conv.png';
import escuchar from '../assets/escuchar.png';

export default function Carditas() {
  return (

    <Container className='mb-5 animawap'>
      <h1 className="text-center mb-5">¿Qué podrás conseguir apoyándote en Cuéntame Otro?</h1>
      <Row className="justify-content-center">
        <Col md={6}>
          <Card className='mb-4 me-4 ms-4 esca lool2 bor'>  
            <Card.Body>
            <div className="in-grid-wrap m-3">
<div className="d-flex align-items-center">
<div className="flex-icon">
<img className="mb-1" src={book1} alt="" width={'80px'}/>
</div>
<h2 className="white boldd line-2 mt-1">Fábulas Únicas</h2>
</div>
<h5 className="white line-2 ms-2">Fomenta la creatividad y valores en tus hijos.</h5>
</div>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6}>
          <Card className='mb-4 ms-4 lool2 me-4 esca bor'>
            <Card.Body>
            <div className="in-grid-wrap m-3">
<div className="d-flex align-items-center">
<div className="flex-icon">
<img className="mb-1" src={escuchar} alt="" width={'80px'}/>
</div>
<h2 className="white boldd line-2 mt-1">Escucha tus Historias</h2>
</div>
<h5 className="white line-2 ms-2">Elige quién deseas que narre la fábula.</h5>
{/* faltCreamomentsJuntsconvImg */}
</div>
            </Card.Body>
          </Card>
        </Col>

        <Col md={6}>
          <Card className='mb-4  ms-4 me-4 lool2 esca bor'>
            <Card.Body>             
            <div className="in-grid-wrap m-3">
<div className="d-flex align-items-center">
<div className="flex-icon">
<img className="ms-2 mb-4 me-2" src={wand} alt="" width={'58px'}/>
</div>
<h2 className="white boldd line-2 mb-4 mt-1">IA Creativa</h2>
</div>
<h5 className="white line-2 ms-2 mt-2 mb-3">Amplía tus cuentos con ayuda de inteligencia artificial.
</h5>
</div>
            </Card.Body>
          </Card>
        </Col>

        <Col md={6}>
          <Card className='mb-4  me-4 ms-4 esca lool2 bor'>            
            <Card.Body>
            <div className="in-grid-wrap m-3">
<div className="d-flex align-items-center">
<div className="flex-icon">
<img className="mb-4 mx-2" src={book3} alt="" width={'65px'}/>
</div>
<h2 className="white boldd line-2 mt-1">Valores Educativos</h2>
</div>
<h5 className="white line-2 ms-2">Enseña lecciones de vida de manera divertida.</h5>
</div>
            </Card.Body>
          </Card>
        </Col>
        
        <Col md={6}>
          <Card className='mb-4 me-4 ms-4 lool2 esca bor'>
            <Card.Body>
            <div className="in-grid-wrap m-3">
<div className="d-flex align-items-center mb-1">
<div className="flex-icon">
<img className="mb-4 mx-2" src={book2} alt="" width={'65px'}/>
</div>
<h2 className="white boldd line-2 mb-3">Fomenta la Lectura</h2>
</div>
<h5 className="white line-2 ms-2">Despierta el amor por la lectura en tus hijos.</h5>
</div>
            </Card.Body>
          </Card>
        </Col>

        <Col md={6}>
          <Card className='mb-4 ms-4 me-4 lool2 esca bor'>
            <Card.Body>            
            <div className="in-grid-wrap m-3 mb-2">
<div className="d-flex align-items-center">
<div className="flex-icon">
<img className="mb-3" src={shelf} alt="" width={'60px'}/>
</div>
<h2 className="white boldd line-2 mb-4">Colección Personalizada</h2>
</div>
<h5 className="white line-2 ms-2">Crea una biblioteca de fábulas únicas, adaptadas a sus intereses y necesidades.</h5>
</div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}
