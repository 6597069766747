import React from 'react'

import logoImg from '../assets/logo1.png';
import youtube from '../assets/youtube.png'
import facebook from '../assets/facebook.png'
import instagram from '../assets/instagram.png'
import mail from '../assets/mail.png'

export default function Footer() {
  return (
    
    <footer className="page-footer font-small blue pt-4 lool">
    <div className="container-fluid text-center text-md-left">
        <div className="row">
            <div className="col-md-6 mt-md-0 mt-3">
                <h5 className="text-uppercase mb-0">Síguenos</h5>
                <a href="/"> <img className='pt-0' src={logoImg} alt="Cuéntame Otro®" width="50" /></a>
                <p className='sinmarbo'>¡Mantente actualizad@ con nosotros!</p>
            
                <div className="mt-2 marfo">
                <a href="" target="_blank" className="social-ico-a inline"><img className="social-ico" src={youtube} alt=""/></a>
<a href="https://instagram.com/cuentame_otro" target="_blank" className="social-ico-a inline"><img className="social-ico" src={instagram} alt=""/></a>
<a href="https://facebook.com/TellMeAnother" target="_blank" className="social-ico-a inline"><img className="social-ico" src={facebook    } alt=""/></a>
<a href="mailto:hola@cuentameotro.com" target="_blank" className="social-ico-a inline"><img className="social-ico" src={mail} alt=""/></a>
                        </div>
            </div>
            <hr className="clearfix w-100 d-md-none pb-0"/>
            <div className="col-md-2 mb-md-0 mb-3">
                <h5 className="text-uppercase">Producto</h5>
                <ul className="list-unstyled">
                    <li><a href="/precios">Precios</a></li>
                    <li><a href="https://app.cuentameotro.com/login">Acceso a usuarios</a></li>
                    <li><a href="mailto:hola@cuentameotro.com">hola@cuentameotro.com</a></li>
                </ul>
            </div>
            <div className="col-md-3 mb-md-0">
                <h5 className="text-uppercase">Términos</h5>
                <ul className="list-unstyled">
                    <li><a href="/terms">Términos & Condiciones de Uso</a></li>
                    <li><a href="/privacy">Política de Privacidad</a></li>
                    <li><a href="/cookies">Política de Cookies</a></li>
                    <li><a href="/legal">Aviso Legal</a></li>
                </ul>
            </div>
        </div>
    </div>
    <hr />
    <div className="footer-copyright text-center pb-3 sinv">© 2023 Copyright | 
        <a href="https://cuentameotro.com/"> CuentameOtro.com</a>
    </div>
</footer>
);
};